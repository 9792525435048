import * as React from "react";
import {
  Divider,
  Descriptions,
  message,
  Button,
  Modal,
  Form,
  Radio,
  Input,
  Steps,
  Result,
  List,
  Select,
  InputNumber,
  Collapse,
  DatePicker,
} from "antd";
import { useHistory, useLocation } from "react-router";
import {
  getContractApplyDetail,
  getContractApplyApprovalLogs,
  contractSalemanApproval,
  contractBusinessSupervisorApproval,
  contractGeneralManagerApproval,
  contractRiskControlPersonApproval,
  contractRiskControlSupervisorApproval,
  contractTaxationStaffApproval,
} from "@/requests/contract";
import { getCompanyInfoByUserId } from "@/requests/credit";

import CompanyInfoDisplayer from "@/ui-components/CompanyInfoDIsplayer";
import ContractApprovalLogDisplayer from "@/ui-components/ContractApprovalLogDisplayer";
import moment from "moment";
import "../styles/detail.less";

enum applyResDes {
  "等待审核中，请尽快完成客户资料补充，提交业务主管审核" = 0,
  "业务人员审核已完成,请结合业务人员审核结果，提交复审意见" = 1,
  "业务员审核失败，申请已关闭" = 2,
  "业务部门审核完成，请结合业务部门审核结果，提交风控审批意见" = 3,
  "业务主管审核不通过，请重新提交审核" = 4,
  "财务人员审核通过，请提交终审意见" = 5,
  "财务人员审核不通过，请重新提交审核" = 6,
  "风控成员审核通过，请结合风控人员审核结果，提交风控复审意见" = 7,
  "风控人员审核不通过，请重新提交审核" = 8,
  "风控部门审核通过，请提交财务审核意见" = 9,
  "风控主管审核不通过，请重新提交审核" = 10,
  "综合授信合同审批完成，已生成合同发送给客户签署" = 11,
  "总经理审核不通过，请重新提交审核意见" = 12,
}
enum applyRes {
  "待业务人员审核" = 1,
  "待业务主管审核" = 2,
  "待风控人员审核" = 3,
  "待风控主管审核" = 4,
  "待财务人员审核" = 5,
  "待总经理审核" = 6,
  "审核完成" = 7,
}

const ContractApplyDetail = () => {
  const location = useLocation();
  const history = useHistory();
  const [data, setData] = React.useState<undefined | ContractApplyDetail>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);
  const [fetching, setFetching] = React.useState(false);

  const [logs, setLogs] = React.useState<OperationLog[]>([]);
  const [approveVisible, setApproveVisible] = React.useState(false);
  const [form] = Form.useForm();
  const [isPass, setIsPass] = React.useState(0);
  const [companyInfo, setCompanyInfo] = React.useState<
    undefined | CompanyInfoItem
  >(undefined);

  const handleApproveCancel = () => {
    setApproveVisible(false);
  };

  const doApproval = async (values: any) => {
    if (!data) return;
    setFetching(true);
    if (data.approval_status === 0 || data.approval_status === 4) {
      const res = await contractSalemanApproval({
        ...(isPass === 1 && {
          after_expiry_date: values.after_expiry_date,
          breach_contract: values.breach_contract,
          breach_contract_formula: values.breach_contract_formula,
          certificate_contract_number: values.certificate_contract_number,
          commitment_fee: values.commitment_fee,
          contract_specification: values.contract_specification,
          pay_type: JSON.stringify({
            户名: values.factoring_bank_account_name,
            开户行: values.factoring_bank_account_subname,
            账号: values.factoring_bank_account,
          }),

          dispute_resolution: values.dispute_resolution,
          dispute_resolution_agency: values.dispute_resolution_agency,
          early_payment_interest: values.early_payment_interest,
          early_repurchase_interest: values.early_repurchase_interest,
          factoring_address: values.factoring_address,
          factoring_contacts: values.factoring_contacts,
          factoring_content: values.factoring_content,
          factoring_contract_number: values.factoring_contract_number,
          factoring_cost: values.factoring_cost,
          factoring_cost_bearer: values.factoring_cost_bearer,
          factoring_cost_deadline: moment(
            values.factoring_cost_deadline
          ).format("YYYY-MM-DD"),
          factoring_cycle: values.factoring_cycle,
          factoring_email: values.factoring_email,
          factoring_mobile: values.factoring_mobile,
          factoring_phone: values.factoring_phone,
          factoring_wechat: values.factoring_wechat,
          hide_notice_type: values.hide_notice_type,
          interest_calculation_formula: values.interest_calculation_formula,
          interest_rate_changes: values.interest_rate_changes,
          limited_certificate_address: values.limited_certificate_address,
          limited_certificate_certificate:
            values.limited_certificate_certificate,
          limited_certificate_contact: values.limited_certificate_contact,
          limited_certificate_email: values.limited_certificate_email,
          limited_certificate_legal_person:
            values.limited_certificate_legal_person,
          limited_certificate_mobile: values.limited_certificate_mobile,
          limited_certificate_name: values.limited_certificate_name,
          limited_certificate_phone: values.limited_certificate_phone,
          limited_certificate_wecht: values.limited_certificate_wecht,
          miscellaneous_cost: values.miscellaneous_cost,
          open_notice_type: values.open_notice_type,
          other_contract: values.other_contract,
          other_prerequisites: values.other_prerequisites,
          security_deposit: values.security_deposit,
          other_security_deposit: values.other_security_deposit,
          other_specification: values.other_specification,
          pay_back_factoring: values.pay_back_factoring,
          pay_back_factoring_synopsis: values.pay_back_factoring_synopsis,
          qualified_specification: values.qualified_specification,
          security_deposit_pay_type: values.security_deposit_pay_type,
          signed_address: values.signed_address,
          signed_type: values.signed_type,
          signed_time: moment(values.signed_time).format("YYYY-MM-DD"),

          tax_burden_changes: values.tax_burden_changes,
          transfer_registration_cost: values.transfer_registration_cost,
          transfer_registration_date: moment(
            values.transfer_registration_date
          ).format("YYYY-MM-DD"),
          transfer_subordinate_rights: values.transfer_subordinate_rights,
          transfer_subordinate_rights_cost:
            values.transfer_subordinate_rights_cost,
          transfer_subordinate_procedure: values.transfer_subordinate_procedure,
        }),

        task_id: data.task_id,

        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 1 || data.approval_status === 8) {
      const res = await contractBusinessSupervisorApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 3 || data.approval_status === 10) {
      const res = await contractRiskControlPersonApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 7 || data.approval_status === 6) {
      const res = await contractRiskControlSupervisorApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 9 || data.approval_status === 12) {
      const res = await contractTaxationStaffApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    if (data.approval_status === 5) {
      const res = await contractGeneralManagerApproval({
        task_id: data.task_id,
        approval: values.approval,
        comment: values.comment,
      });
      if (res.code === 0) {
        message.success("提交成功");
        fetchData();
        setApproveVisible(false);
      } else {
        message.error(res.message);
      }
    }
    setFetching(false);
  };
  const handleApproveOk = (values: any) => {
    if (!data) return;
    console.log("xxxxxxxx", values);
    doApproval(values);
  };
  const fetchData = async () => {
    let id: string = "";
    if (location.state) {
      const state = location.state as any;
      id = state.id;
    } else if (location.search) {
      id = location.search.split("=")[1];
    }
    if (!id) return;
    const res = await getContractApplyDetail(id);
    if (res.code === 0) {
      setData(res.data);
      if (!res.data) return;
      fetchApprovalLog(res.data.contract_uid);

      fetchCompanyInfo(res.data.user_id);
    } else {
      message.error(res.message);
    }
    setLoading(false);
  };
  const fetchApprovalLog = async (id: string) => {
    if (!id) return;
    const res = await getContractApplyApprovalLogs(id);
    if (res.code === 0) {
      if (res.data) {
        setLogs(res.data);
      }
    } else {
    }
  };

  const fetchCompanyInfo = async (
    user_id: ContractApplyListItem["user_id"]
  ) => {
    const res = await getCompanyInfoByUserId(user_id);
    if (res.code === 0) {
      setCompanyInfo(res.data);
    } else {
    }
  };

  const getCurrent = (status: number) => {
    if (status === 0 || status === 4) {
      return 1;
    } else if (status === 1 || status === 8) {
      return 2;
    } else if (status === 3 || status === 10) {
      return 3;
    } else if (status === 7 || status === 6) {
      return 4;
    } else if (status === 9 || status === 12) {
      return 5;
    } else if (status === 5) {
      return 6;
    } else if (status === 11) {
      return 7;
    } else {
      return 7;
    }
  };

  const getStatus = (status: number) => {
    if (
      status === 0 ||
      status === 1 ||
      status === 3 ||
      status === 5 ||
      status === 7 ||
      status === 9
    ) {
      return "info";
    } else if (
      status === 2 ||
      status === 4 ||
      status === 6 ||
      status === 8 ||
      status === 10 ||
      status === 12
    ) {
      return "error";
    } else if (status === 11) {
      return "success";
    }
  };

  React.useEffect(() => {
    if (!location.search) return;
    fetchData();
  }, [location]);
  return (
    <div style={{ padding: 16, backgroundColor: "#ffffff" }}>
      {data && (
        <>
          <Steps
            current={getCurrent(data.approval_status)}
            size="small"
            style={{ margin: "16px 0" }}
          >
            <Steps.Step title="提交" description="用户提交申请"></Steps.Step>
            <Steps.Step title="业务员审核" description={"业务员"}></Steps.Step>
            <Steps.Step
              title="业务主管审核"
              description="业务主管"
            ></Steps.Step>
            <Steps.Step
              title="风控人员审核"
              description="风控人员"
            ></Steps.Step>
            <Steps.Step
              title="风控主管审核"
              description="风控主管"
            ></Steps.Step>
            <Steps.Step title="财务审核" description="财务主管"></Steps.Step>
            <Steps.Step title="总经理审核" description="总经理"></Steps.Step>

            <Steps.Step
              title="审核完成"
              description="完成合同审批"
            ></Steps.Step>
          </Steps>
          <Result
            style={{ padding: 16 }}
            status={getStatus(data.approval_status)}
            title={applyRes[getCurrent(data.approval_status)]}
            subTitle={applyResDes[data.approval_status]}
            extra={[
              data.approval_status !== 2 && data.approval_status !== 11 && (
                <Button
                  type="primary"
                  onClick={() => {
                    setApproveVisible(true);
                  }}
                >
                  提交审核结果
                </Button>
              ),
            ]}
          />
          <Divider>用户提交内容</Divider>
          <Descriptions>
            <Descriptions.Item label="应收账款指定接收人姓名">
              {data.creditor_contacts}
            </Descriptions.Item>
            <Descriptions.Item label="应收账款指定送达地址">
              {data.creditor_address}
            </Descriptions.Item>
            <Descriptions.Item label="应收账款指定接收人电话（座机）">
              {data.creditor_phone ? data.creditor_phone : "无"}
            </Descriptions.Item>
            <Descriptions.Item label="应收账款指定接收人手机">
              {data.creditor_mobile}
            </Descriptions.Item>
            <Descriptions.Item label="应收账款指定接收人电子邮箱">
              {data.creditor_email}
            </Descriptions.Item>
            <Descriptions.Item label="应收账款指定接收人即时通讯号（微信）">
              {data.creditor_wechat}
            </Descriptions.Item>
            <Descriptions.Item label="收账款指定接收银行账户户名">
              {JSON.parse(data.creditor_account)["户名"]}
            </Descriptions.Item>
            <Descriptions.Item label="应收账款指定接收银行账户账号">
              {JSON.parse(data.creditor_account)["账号"]}
            </Descriptions.Item>
            <Descriptions.Item label="应收账款指定接收银行账户开户行">
              {JSON.parse(data.creditor_account)["开户行"]}
            </Descriptions.Item>
            <Descriptions.Item label="发票开具方式">
              {data.invoicing_method}
            </Descriptions.Item>
            <Descriptions.Item label="申请时间">
              {data.create_time}
            </Descriptions.Item>
          </Descriptions>
          {companyInfo && (
            <Collapse>
              <Collapse.Panel key="1" header="企业基础资料(点击展开)">
                <CompanyInfoDisplayer data={companyInfo} />
              </Collapse.Panel>
            </Collapse>
          )}
          {logs && (
            <>
              <Divider>审批记录</Divider>
              <List
                dataSource={logs}
                renderItem={(item) => (
                  <List.Item key={item.id}>
                    <ContractApprovalLogDisplayer data={item} />
                  </List.Item>
                )}
              />
              <Divider></Divider>
            </>
          )}
        </>
      )}
      {data && companyInfo && (
        <Modal
          title="合同审批"
          footer={null}
          onCancel={handleApproveCancel}
          visible={approveVisible}
          destroyOnClose
          width={"80%"}
        >
          <Form
            form={form}
            layout="vertical"
            onFinish={handleApproveOk}
            preserve={false}
            className="input-form"
          >
            {(data.approval_status === 0 || data.approval_status === 4) &&
              isPass === 1 && (
                <>
                  <div style={{ display: "flex", flexWrap: "wrap" }}>
                    <Form.Item
                      className="form-item"
                      label="保理服务内容"
                      name="factoring_content"
                      rules={[
                        { required: true, message: "请选择保理服务内容" },
                      ]}
                      initialValue={[1, 2]}
                    >
                      <Select mode="multiple">
                        <Select.Option value={1}>资金融通</Select.Option>
                        <Select.Option value={2}>应收账款管理</Select.Option>
                        <Select.Option value={3}>应收账款催收</Select.Option>
                        <Select.Option value={4}>
                          应收账款债务人付款担保服务
                        </Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="form-item"
                      name="signed_address"
                      label="合同签订地点"
                      rules={[
                        { required: true, message: "请输入合同签订地点" },
                      ]}
                      initialValue={"成都市高新区"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="signed_time"
                      label="合同签订时间"
                      rules={[
                        { required: true, message: "请输入合同签订时间" },
                      ]}
                      initialValue={moment(new Date())}
                    >
                      <DatePicker style={{ width: "100%" }}></DatePicker>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="signed_type"
                      label="合同签订方式"
                      rules={[
                        { required: true, message: "请选择合同签订方式" },
                      ]}
                      initialValue={2}
                    >
                      <Select>
                        <Select.Option value={1}>线下签订</Select.Option>
                        <Select.Option value={2}>线上签订</Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="form-item"
                      name="certificate_contract_number"
                      label="应收账款债权人合同份数"
                      rules={[
                        {
                          required: true,
                          message: "请输入应收账款债权人合同份数",
                        },
                      ]}
                      initialValue={"壹"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_contract_number"
                      label="商业保理人合同份数"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人合同份数",
                        },
                      ]}
                      initialValue={"壹"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="other_contract"
                      label="其他合同文件"
                      rules={[
                        {
                          required: true,
                          message: "请输入其他合同文件",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="other_specification"
                      label="其他规范性文件"
                      rules={[
                        {
                          required: true,
                          message: "请输入其他规范性文件",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="contract_specification"
                      label="适用于本合同的标准"
                      rules={[
                        {
                          required: true,
                          message: "请输入适用于本合同的标准",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>

                    <Form.Item
                      className="form-item"
                      name="factoring_contacts"
                      label="商业保理人指定接收人姓名"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定接收人姓名",
                        },
                      ]}
                      initialValue={"徐元涛"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_address"
                      label="商业保理人指定送达地址"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定送达地址",
                        },
                      ]}
                      initialValue={"成都市武侯区领事馆路7号保利中心南塔2103"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_phone"
                      label="商业保理人指定接收人电话（座机）"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定接收人电话（座机）",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_mobile"
                      label="商业保理人指定接收人手机"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定接收人手机",
                        },
                      ]}
                      initialValue={"18080461487"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_email"
                      label="商业保理人指定接收人电子邮箱"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定接收人电子邮箱",
                        },
                      ]}
                      initialValue={"xuyuantao@lbaoli.com"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_wechat"
                      label="商业保理人指定接收人即时通讯号（微信）"
                      rules={[
                        {
                          required: true,
                          message:
                            "请输入商业保理人指定接收人即时通讯号（微信）",
                        },
                      ]}
                      initialValue={"18080461487"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="qualified_specification"
                      label="转让应收账款应符合的标准"
                      rules={[
                        {
                          required: true,
                          message: "请输入转让应收账款应符合的标准",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="open_notice_type"
                      label="公开型保理转让通知方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入公开型保理转让通知方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="hide_notice_type"
                      label="隐蔽型保理转让通知方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入隐蔽型保理转让通知方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="transfer_registration_date"
                      label="应收账款转让登记的日期"
                      rules={[
                        {
                          required: true,
                          message: "请输入应收账款转让登记的日期",
                        },
                      ]}
                      initialValue={moment(new Date())}
                    >
                      <DatePicker style={{ width: "100%" }}></DatePicker>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="transfer_registration_cost"
                      label="转让登记费的费用承担"
                      rules={[
                        {
                          required: true,
                          message: "请输入转让登记费的费用承担方式",
                        },
                      ]}
                      initialValue={"商业保理人"}
                    >
                      <Select>
                        <Select.Option value={"商业保理人"}>
                          商业保理人
                        </Select.Option>
                        <Select.Option value={"应收账款债权人"}>
                          应收账款债权人
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="transfer_subordinate_rights"
                      label="应收账款债权人转让给商业保理人的从属权利和权益"
                      rules={[
                        {
                          required: true,
                          message:
                            "请输入应收账款债权人转让给商业保理人的从属权利和权益",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="transfer_subordinate_procedure"
                      label="从属权利和权益转让应完成的手续"
                      rules={[
                        {
                          required: true,
                          message: "请输入从属权利和权益转让应完成的手续",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="transfer_subordinate_rights_cost"
                      label="从属权利和权益转让手续费的承担"
                      rules={[
                        {
                          required: true,
                          message: "请输入从属权利和权益转让手续费的承担",
                        },
                      ]}
                      initialValue={"商业保理人"}
                    >
                      <Select>
                        <Select.Option value={"商业保理人"}>
                          商业保理人
                        </Select.Option>
                        <Select.Option value={"应收账款债权人"}>
                          应收账款债权人
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_name"
                      label="限定债务人姓名或名称"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人姓名或名称",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_certificate"
                      label="限定债务人身份证件名称及号码"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人身份证件名称及号码",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_address"
                      label="限定债务人联系地址"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人联系地址",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_legal_person"
                      label="限定债务人法定代表人姓名"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人法定代表人姓名",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_contact"
                      label="限定债务人联系人姓名"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人联系人姓名",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_phone"
                      label="限定债务人联系人电话（座机）"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人联系人电话（座机）",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_mobile"
                      label="限定债务人联系人手机号"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人联系人手机号",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_wecht"
                      label="限定债务人联系人即时通讯号（微信）"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人联系人即时通讯号（微信）",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="limited_certificate_email"
                      label="限定债务人联系人电子邮箱"
                      rules={[
                        {
                          required: true,
                          message: "请输入限定债务人联系人电子邮箱",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      label="第一期保理服务费金额"
                    >
                      <Form.Item
                        name="factoring_cost"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "请输入第一期保理服务费金额",
                          },
                        ]}
                        initialValue={0}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="必填"
                        />
                      </Form.Item>
                      <span style={{ paddingLeft: 5 }}>元</span>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_cost_bearer"
                      label="保理服务费承担方"
                      rules={[
                        {
                          required: true,
                          message: "请选择保理服务费承担方",
                        },
                      ]}
                      initialValue={"应收账款债权人"}
                    >
                      <Select>
                        <Select.Option value={"商业保理人"}>
                          商业保理人
                        </Select.Option>
                        <Select.Option value={"应收账款债权人"}>
                          应收账款债权人
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_cost_deadline"
                      label="保理服务费支付期限"
                      rules={[
                        {
                          required: true,
                          message: "请输入保理服务费支付期限",
                        },
                      ]}
                      initialValue={moment(new Date())}
                    >
                      <DatePicker style={{ width: "100%" }}></DatePicker>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="miscellaneous_cost"
                      label="杂费的种类、金额以及支付期限"
                      rules={[
                        {
                          required: true,
                          message: "请输入杂费的种类、金额以及支付期限",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_bank_account_name"
                      label="商业保理人指定银行账户户名"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定银行账户户名",
                        },
                      ]}
                      initialValue={"深圳前海兰青商业保理有限公司"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_bank_account_subname"
                      label="商业保理人指定银行账户开户行"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定银行账户开户行",
                        },
                      ]}
                      initialValue={"浙江民泰银行成都成华支行"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_bank_account"
                      label="商业保理人指定银行账户账号"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人指定银行账户账号",
                        },
                      ]}
                      initialValue={"584244223788888"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="factoring_cycle"
                      label="保理融资是否可循环"
                      rules={[
                        {
                          required: true,
                          message: "请选择保理融资是否可循环",
                        },
                      ]}
                      initialValue={"是"}
                    >
                      <Select>
                        <Select.Option value={"是"}>是</Select.Option>
                        <Select.Option value={"否"}>否</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="after_expiry_date"
                      label="应收账款到期日可否晚于保理融资额度有效期的届满日"
                      rules={[
                        {
                          required: true,
                          message:
                            "请选择应收账款到期日可否晚于保理融资额度有效期的届满日",
                        },
                      ]}
                      initialValue={"是"}
                    >
                      <Select>
                        <Select.Option value={"是"}>是</Select.Option>
                        <Select.Option value={"否"}>否</Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item className="form-item" label="额度承诺费金额">
                      <Form.Item
                        name="commitment_fee"
                        noStyle
                        rules={[
                          {
                            required: true,
                            message: "请输入额度承诺费金额",
                          },
                        ]}
                        initialValue={0}
                      >
                        <InputNumber
                          style={{ width: "100%" }}
                          placeholder="必填"
                        />
                      </Form.Item>
                      <span style={{ paddingLeft: 5 }}>元</span>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="security_deposit"
                      label="保证金的金额、支付方式及期限"
                      rules={[
                        {
                          required: true,
                          message: "请输入保证金的金额、支付方式及期限",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="other_security_deposit"
                      label="其他履约担保的金额、方式及期限"
                      rules={[
                        {
                          required: true,
                          message: "请输入其他履约担保的金额、方式及期限",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="other_prerequisites"
                      label="其他保理融资前提条件"
                      rules={[
                        {
                          required: true,
                          message: "请输入其他保理融资前提条件",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="security_deposit_pay_type"
                      label="融资款支付方式"
                      rules={[
                        {
                          required: true,
                          message: "请选择融资款支付方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Select>
                        <Select.Option value={"银行转账"}>
                          银行转账
                        </Select.Option>
                      </Select>
                    </Form.Item>

                    <Form.Item
                      className="form-item"
                      name="interest_calculation_formula"
                      label="融资利息计算公式"
                      rules={[
                        {
                          required: true,
                          message: "请输入融资利息计算公式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="interest_rate_changes"
                      label="利率变化方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入利率变化方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="early_payment_interest"
                      label="提前清偿利率计算方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入提前清偿利率计算方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="early_repurchase_interest"
                      label="提前回购利率计算方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入提前回购利率计算方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="tax_burden_changes"
                      label="税负变化时收费调整方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入税负变化时收费调整方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="pay_back_factoring"
                      label="直接回款保理的回收及分配方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入直接回款保理的回收及分配方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="pay_back_factoring_synopsis"
                      label="间接回款保理的回收及分配方式"
                      rules={[
                        {
                          required: true,
                          message: "请输入间接回款保理的回收及分配方式",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="breach_contract"
                      label="商业保理人的其他违约情形"
                      rules={[
                        {
                          required: true,
                          message: "请输入商业保理人的其他违约情形",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="breach_contract_formula"
                      label="商业保理人违约责任的其他承担方式和计算方法"
                      rules={[
                        {
                          required: true,
                          message:
                            "请输入商业保理人违约责任的其他承担方式和计算方法",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="dispute_resolution"
                      label="争议解决"
                      rules={[
                        {
                          required: true,
                          message: "请选择争议解决方式",
                        },
                      ]}
                      initialValue={1}
                    >
                      <Select>
                        <Select.Option value={1}>
                          向仲裁委员会申请仲裁
                        </Select.Option>
                        <Select.Option value={2}>
                          向成都市高新技术开发区人民法院起诉
                        </Select.Option>
                        <Select.Option value={3}>
                          向其他机构公证处申请赋予强制执行公证
                        </Select.Option>
                      </Select>
                    </Form.Item>
                    <Form.Item
                      className="form-item"
                      name="dispute_resolution_agency"
                      label="其他争议解决机构"
                      rules={[
                        {
                          required: true,
                          message: "请输入其他争议解决机构",
                        },
                      ]}
                      initialValue={"无"}
                    >
                      <Input placeholder="必填" />
                    </Form.Item>
                  </div>
                </>
              )}

            <Form.Item
              label="审批结果"
              name="approval"
              rules={[{ required: true, message: "请选择审批结果" }]}
              initialValue={isPass}
            >
              <Radio.Group onChange={(e) => setIsPass(e.target.value)}>
                <Radio value={0}>不通过</Radio>
                <Radio value={1}>通过</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label="审批意见"
              name="comment"
              rules={[{ required: true, message: "请输入审批意见" }]}
            >
              <Input.TextArea placeholder="请输入审批意见"></Input.TextArea>
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  marginTop: 36,
                  padding: "0 100px",
                }}
              >
                <Button
                  type="primary"
                  htmlType={"submit"}
                  style={{ padding: "0 36px" }}
                  loading={fetching}
                >
                  确认
                </Button>
                <Button
                  onClick={handleApproveCancel}
                  style={{ padding: "0 36px" }}
                >
                  取消
                </Button>
              </div>
            </Form.Item>
          </Form>
        </Modal>
      )}
    </div>
  );
};

export default ContractApplyDetail;
